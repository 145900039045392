// File: src/components/Transfers/PickupLocationDropdown.js
// Purpose: Dropdown component for selecting pickup locations based on the selected year ID, integrated with Redux and selectors.
// Related Files: transfersSelectors.js, transfersSlice.js, transfersSaga.js, Transfers.module.css

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'antd/lib/select';
import {
    selectPickupLocations,
    selectIsPickupLocationsLoading,
    selectSelectedPickupLocation,
} from '../../../redux/selectors/transfersSelectors';
import {
    fetchPickupLocationsRequest,
    selectPickupLocation,
} from '../../../redux/slices/transfersSlice';
import styles from 'styles/Transfers.module.css';

const { Option } = Select;

const PickupLocationDropdown = () => {
    const dispatch = useDispatch();

    const pickupLocations = useSelector(selectPickupLocations);
    const loading = useSelector(selectIsPickupLocationsLoading);
    const selectedPickup = useSelector(selectSelectedPickupLocation);
    const yearId = useSelector((state) => state.transfers.selectedYearId);

    useEffect(() => {
        if (yearId) {
            dispatch(fetchPickupLocationsRequest(yearId));
        }
    }, [yearId, dispatch]);

    const handlePickupSelect = (value, option) => {
        dispatch(selectPickupLocation({ id: value, name: option ? option.children : '' }));
    };

    return (
        <Select
            className={styles.reactSelectControl}
            value={selectedPickup?.id || null}
            onChange={handlePickupSelect}
            placeholder="Select a pickup location"
            allowClear
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
            }
            notFoundContent={loading ? "Loading options..." : "No data available"}
            style={{ width: '100%' }}
        >
            {pickupLocations.map((location) => (
                <Option key={location.id} value={location.id}>
                    {location.name}
                </Option>
            ))}
        </Select>
    );
};

export default PickupLocationDropdown;