// File: src/redux/sagas/rootSaga.js
// Purpose: Combines all individual sagas into a single root saga.

import { all, fork } from "redux-saga/effects";
import transfersSaga from "./transfersSaga"; 
import databoardTransfersSaga from "../../components/Databoard/modules/Transfers/databoardTransfersSaga"; 
import { watchHotelSearchSagas } from "./hotelSearchSaga";

export default function* rootSaga() {
    yield all([
        fork(transfersSaga),  
        fork(databoardTransfersSaga),  
        fork(watchHotelSearchSagas), 
    ]);
}
