// File: src/redux/transformers/databoardTransfersTransformers.js
import { addLog } from "../../LogViewer";

// Helper function to validate and extract results
const extractResults = (apiData, transformerName) => {
  if (!apiData || !apiData.results || !Array.isArray(apiData.results)) {
    const error = `Invalid data format for ${transformerName}.`;
    addLog("Transformer", `${transformerName} error`, { error });
    throw new Error(error);
  }
  addLog("Transformer", `${transformerName} data received`, { count: apiData.results.length });
  return apiData.results;
};

// Normalize years data
export const normalizeYears = (apiData) => {
  const results = extractResults(apiData, "normalizeYears");
  addLog("Transformer", "normalizeYears executed", { normalizedCount: results.length });
  return results.map((item) => ({
    id: item.id,
    year: item.year,
  }));
};

// Normalize pickup locations
export const normalizePickupLocations = (apiData) => {
  const results = extractResults(apiData, "normalizePickupLocations");
  addLog("Transformer", "normalizePickupLocations executed", { normalizedCount: results.length });
  return results.map((item) => ({
    id: item.id,
    name: item.pickup_location, // Assuming `pickup_location` is the key for the location name
    year: item.year,
  }));
};

// Normalize dropoff locations
export const normalizeDropoffLocations = (apiData) => {
  const results = extractResults(apiData, "normalizeDropoffLocations");
  addLog("Transformer", "normalizeDropoffLocations executed", { normalizedCount: results.length });
  return results.map((item) => ({
    id: item.id,
    name: item.dropoff_location, // Assuming `dropoff_location` is the key for the location name
    year: item.year,
  }));
};

// Normalize transfers
export const normalizeTransfers = (apiData) => {
  const results = extractResults(apiData, "normalizeTransfers");
  addLog("Transformer", "normalizeTransfers executed", { normalizedCount: results.length });
  return results.map((item) => ({
    id: item.id,
    year: item.year,
    pickupLocation: item.pickup_location,
    dropoffLocation: item.dropoff_location,
    groupSize: item.group_size,
    netRateWithTaxes: item.net_rate_with_taxes,
    rackRateWithTaxes: item.rack_rate_with_taxes,
  }));
};

// Default export for transformers
export default {
  normalizeYears,
  normalizePickupLocations,
  normalizeDropoffLocations,
  normalizeTransfers,
};