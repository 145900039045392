// File: src/routes/AppRoutes.js
// Purpose: Defines the main application routes, including the Databoard and Dashboard modules.

import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "components/LoginPage/Login";
import MainDashboard from "components/Dashboard/MainDashboard";
import HotelRatesSearch from "components/Dashboard/HotelRatesSearch";
import TransfersSearchInterface from "components/Dashboard/Transfers/TransfersFrontpage";
import ToursSearchInterface from "components/Dashboard/Tours/ToursFrontpage";
import RentalCarsFrontpage from "components/Dashboard/RentalCars/RentalCarsFrontpage";
import DomesticFlightsFrontpage from "components/Dashboard/DomesticFlights/DomesticFlightsFrontpage";
import DataboardLayout from "components/Databoard/DataboardLayout";
import DataboardTransfersPage from "components/Databoard/modules/Transfers/UnifiedTransfersPage"; // Corrected import path

const AppRoutes = () => (
  <Routes>
    {/* Login Page */}
    <Route path="/login" element={<Login />} />

    {/* Dashboard Module */}
    <Route path="/dashboard/*" element={<MainDashboard />}>
      <Route
        index
        element={
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            Click a tab to begin
          </div>
        }
      />
      <Route path="hotelsearch" element={<HotelRatesSearch />} />
      <Route path="transfers" element={<TransfersSearchInterface />} />
      <Route path="tours" element={<ToursSearchInterface />} />
      <Route path="rentalcars" element={<RentalCarsFrontpage />} />
      <Route path="domesticflights" element={<DomesticFlightsFrontpage />} />
    </Route>

    {/* Databoard Module */}
    <Route path="/databoard/*" element={<DataboardLayout />}>
      {/* Default Databoard Welcome Page */}
      <Route
        index
        element={
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <h2>Welcome to the Databoard</h2>
            <p>Select a module from the sidebar to get started.</p>
          </div>
        }
      />
      {/* Transfers Module */}
      <Route path="transfers" element={<DataboardTransfersPage />} />
    </Route>

    {/* Fallback Route */}
    <Route path="*" element={<Login />} />
  </Routes>
);

export default AppRoutes;