// File: src/components/Databoard/Modules/Transfers/TransfersTable.js
// Purpose: Table displaying transfer data with actions for editing and deleting.

import React from "react";
import { Table, Button } from "antd";
import { useSelector, useDispatch } from "react-redux";
import "./Transfers.module.css";

const TransfersTable = () => {
  const dispatch = useDispatch();
  const { transfers, loading } = useSelector((state) => state.databoardTransfers);

  const handleEdit = (record) => {
    console.log("Editing transfer:", record);
    // Dispatch an action or handle edit logic here
  };

  const handleDelete = (id) => {
    dispatch({ type: "databoardTransfers/deleteTransfer", payload: id });
  };

  const columns = [
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Pickup Location",
      dataIndex: "pickupLocation",
      key: "pickupLocation",
    },
    {
      title: "Dropoff Location",
      dataIndex: "dropoffLocation",
      key: "dropoffLocation",
    },
    {
      title: "Group Size",
      dataIndex: "groupSize",
      key: "groupSize",
    },
    {
      title: "Net Rate (with Taxes)",
      dataIndex: "netRateWithTaxes",
      key: "netRateWithTaxes",
      render: (value) => `$${value.toFixed(2)}`,
    },
    {
      title: "Rack Rate (with Taxes)",
      dataIndex: "rackRateWithTaxes",
      key: "rackRateWithTaxes",
      render: (value) => `$${value.toFixed(2)}`,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div className="table-actions">
          <Button type="link" onClick={() => handleEdit(record)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => handleDelete(record.id)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={transfers}
      rowKey="id"
      loading={loading}
      pagination={{ pageSize: 10 }}
      className="transfers-table"
    />
  );
};

export default TransfersTable;