// File: src/components/Dashboard/HotelRatesSearch/index.js
// Purpose: Manages the hotel rates search page, integrating search, display, and logging components.
//          Refactored to remove hotelLocationSlice dependencies and use hotelSearchSlice for fetching hotels.

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchHotels } from "../../../redux/slices/hotelSearchSlice"; // Updated import
import HotelSearchBar from "./HotelSearchBar";
import HotelRatesDisplay from "./HotelRatesDisplay";
import { Layout } from "antd";
import styles from "styles/HotelRatesSearch.module.css";

const { Content } = Layout;

const HotelRatesSearch = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHotels(null)); // Corrected function call
  }, [dispatch]);

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content style={{ padding: "24px", backgroundColor: "#fff" }}>
        <div className={styles.hotelSearchContainer}>
          <HotelSearchBar />
          <HotelRatesDisplay />
        </div>
      </Content>
    </Layout>
  );
};

export default HotelRatesSearch;