// File: src/components/Transfers/DropoffLocationDropdown.js
// Purpose: Dropdown component for selecting drop-off locations based on the selected pickup location, integrated with Redux and selectors.
// Related Files: transfersSelectors.js, transfersSlice.js, transfersSaga.js, Transfers.module.css

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'antd/lib/select';
import {
    selectDropoffLocations,
    selectIsDropoffLocationsLoading,
    selectSelectedDropoffLocation,
} from '../../../redux/selectors/transfersSelectors';
import {
    fetchDropoffLocationsRequest,
    selectDropoffLocation,
} from '../../../redux/slices/transfersSlice';
import styles from 'styles/Transfers.module.css';

const { Option } = Select;

const DropoffLocationDropdown = () => {
    const dispatch = useDispatch();

    const dropOffLocations = useSelector(selectDropoffLocations);
    const loading = useSelector(selectIsDropoffLocationsLoading);
    const selectedDropOff = useSelector(selectSelectedDropoffLocation);
    const pickupId = useSelector((state) => state.transfers.selectedPickupLocation.id);

    useEffect(() => {
        if (pickupId) {
            dispatch(fetchDropoffLocationsRequest(pickupId));
        }
    }, [pickupId, dispatch]);

    const handleDropOffSelect = (value, option) => {
        dispatch(selectDropoffLocation({ id: value, name: option ? option.children : '' }));
    };

    return (
        <Select
            className={styles.reactSelectControl}
            value={selectedDropOff?.id || null}
            onChange={handleDropOffSelect}
            placeholder="Select a drop-off location"
            allowClear
            showSearch
            filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
            }
            notFoundContent={loading ? "Loading..." : "No data available"}
            style={{ width: '100%' }}
        >
            {dropOffLocations.map((location) => (
                <Option key={location.id} value={location.id}>
                    {location.name}
                </Option>
            ))}
        </Select>
    );
};

export default DropoffLocationDropdown;