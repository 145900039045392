// File: src/components/Dashboard/HotelRatesSearch/OccupancySelector.js
// Purpose: Allows users to select the number of adults, children, and children's ages while respecting max occupancy.

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, InputNumber, Typography } from "antd";
import styles from "styles/HotelRatesSearch.module.css";
import {
    setNumAdults,
    setNumChildren,
    setChildrenAges,
    setBaselineRates, // Added to listen for state reset
} from "../../../redux/slices/hotelSearchSlice";

const { Text, Title } = Typography;

const OccupancySelector = () => {
    const dispatch = useDispatch();

    // Retrieve values from Redux store
    const numAdults = useSelector((state) => state.hotelSearch.numAdults);
    const numChildren = useSelector((state) => state.hotelSearch.numChildren);
    const childrenAges = useSelector((state) => state.hotelSearch.childrenAges);
    const maxOccupancy = useSelector((state) => state.hotelSearch.maxOccupancy);
    const baselineRates = useSelector((state) => state.hotelSearch.baselineRates); // Watch for state reset

    const totalGuests = numAdults + numChildren;
    const validMaxOccupancy = typeof maxOccupancy === "number" && maxOccupancy > 0;
    const remainingSpots = validMaxOccupancy ? maxOccupancy - totalGuests : null;

    useEffect(() => {
        // ✅ Reset Travelers when state resets (baselineRates changes)
        if (baselineRates.length === 0) {
            dispatch(setNumAdults(1));
            dispatch(setNumChildren(0));
            dispatch(setChildrenAges([]));
        }
    }, [baselineRates, dispatch]);

    const handleAdultsChange = (value) => {
        if (value < 1) return; // Ensure at least 1 adult
        if (validMaxOccupancy && value + numChildren > maxOccupancy) return; // Prevent exceeding max
        dispatch(setNumAdults(value));
    };

    const handleChildrenChange = (value) => {
        if (validMaxOccupancy && numAdults + value > maxOccupancy) return; // Prevent exceeding max
        const newChildrenCount = Math.max(0, value);
        dispatch(setNumChildren(newChildrenCount));

        // ✅ Adjust children's ages array dynamically
        if (newChildrenCount < childrenAges.length) {
            dispatch(setChildrenAges(childrenAges.slice(0, newChildrenCount)));
        } else {
            dispatch(setChildrenAges([...childrenAges, ...Array(newChildrenCount - childrenAges.length).fill(null)]));
        }
    };

    const handleChildAgeChange = (index, value) => {
        const updatedAges = [...childrenAges];
        updatedAges[index] = value;
        dispatch(setChildrenAges(updatedAges));
    };

    return (
        <div className={styles.occupancySelectorContainer}>
            <Title level={4} className={styles.occupancyTitle}>
                Travelers
            </Title>

            {/* Adults Section */}
            <div className={styles.numberInputContainer}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Text>Adults</Text>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <Button
                            shape="circle"
                            onClick={() => handleAdultsChange(numAdults - 1)}
                            disabled={numAdults === 1} // Ensure at least 1 adult
                        >
                            -
                        </Button>
                        <Text>{numAdults}</Text>
                        <Button
                            shape="circle"
                            onClick={() => handleAdultsChange(numAdults + 1)}
                            disabled={validMaxOccupancy && totalGuests >= maxOccupancy} // Prevent exceeding max
                        >
                            +
                        </Button>
                    </div>
                </div>
            </div>

            {/* Children Section */}
            <div className={styles.numberInputContainer}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Text>Children</Text>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                        <Button
                            shape="circle"
                            onClick={() => handleChildrenChange(numChildren - 1)}
                            disabled={numChildren === 0}
                        >
                            -
                        </Button>
                        <Text>{numChildren}</Text>
                        <Button
                            shape="circle"
                            onClick={() => handleChildrenChange(numChildren + 1)}
                            disabled={validMaxOccupancy && totalGuests >= maxOccupancy} // Prevent exceeding max
                        >
                            +
                        </Button>
                    </div>
                </div>

                {/* ✅ Children's Ages Section */}
                {numChildren > 0 && (
                    <div style={{ marginTop: "16px" }}>
                        <Text strong>Children's Ages (0 to 17)</Text>
                        <div>
                            {Array.from({ length: numChildren }).map((_, index) => (
                                <InputNumber
                                    key={index}
                                    placeholder={`Child ${index + 1}`}
                                    min={0}
                                    max={17}
                                    value={childrenAges[index] || ""}
                                    onChange={(value) => handleChildAgeChange(index, value)}
                                    style={{ width: "100%", marginTop: "8px" }}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>

            {/* ✅ FIX: No error should show until a hotel is selected */}
            {validMaxOccupancy && totalGuests > maxOccupancy && (
                <p className={styles.errorMessage}>
                    ⚠️ Maximum occupancy of {maxOccupancy} exceeded. Adjust the number of guests.
                </p>
            )}
        </div>
    );
};

export default OccupancySelector;