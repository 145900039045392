// File: src/components/Databoard/Modules/Transfers/databoardTransfersSlice.js
// Purpose: Redux slice for managing Transfers state.

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  years: [],
  pickupLocations: [],
  dropoffLocations: [],
  transfers: [],
  loading: false,
  error: null,
  pagination: {
    currentPage: 1,
    totalPages: 0,
  },
};

const databoardTransfersSlice = createSlice({
  name: "databoardTransfers",
  initialState,
  reducers: {
    // Fetch Years
    fetchYearsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchYearsSuccess: (state, action) => {
      state.loading = false;
      state.years = action.payload;
    },
    fetchYearsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Fetch Pickup Locations
    fetchPickupLocationsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchPickupLocationsSuccess: (state, action) => {
      state.loading = false;
      state.pickupLocations = action.payload;
    },
    fetchPickupLocationsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Fetch Dropoff Locations
    fetchDropoffLocationsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDropoffLocationsSuccess: (state, action) => {
      state.loading = false;
      state.dropoffLocations = action.payload;
    },
    fetchDropoffLocationsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Fetch Transfers
    fetchTransfersRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchTransfersSuccess: (state, action) => {
      state.loading = false;
      state.transfers = action.payload.transfers;
      state.pagination = action.payload.pagination;
    },
    fetchTransfersFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    // Delete Transfer
    deleteTransferRequest: (state) => {
      state.loading = true;
    },
    deleteTransferSuccess: (state, action) => {
      state.loading = false;
      state.transfers = state.transfers.filter((t) => t.id !== action.payload);
    },
    deleteTransferFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchYearsRequest,
  fetchYearsSuccess,
  fetchYearsFailure,
  fetchPickupLocationsRequest,
  fetchPickupLocationsSuccess,
  fetchPickupLocationsFailure,
  fetchDropoffLocationsRequest,
  fetchDropoffLocationsSuccess,
  fetchDropoffLocationsFailure,
  fetchTransfersRequest,
  fetchTransfersSuccess,
  fetchTransfersFailure,
  deleteTransferRequest,
  deleteTransferSuccess,
  deleteTransferFailure,
} = databoardTransfersSlice.actions;

export default databoardTransfersSlice.reducer;