// File: src/redux/slices/hotelSearchSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  countries: [],
  regions: [],
  hotels: [],
  roomTypes: [],
  availableDateRanges: { startDate: null, endDate: null, ranges: [] },
  baselineRates: [],
  guestCharges: [],
  
  availableDates: [],
  lastAvailableDate: null,

  selectedCountry: null,
  selectedRegion: null,
  selectedHotel: null,
  selectedRoomType: null,
  selectedDates: { startDate: null, endDate: null },

  numAdults: 1,
  numChildren: 0,
  childrenAges: [],
  maxOccupancy: null,

  loadingCountries: false,
  loadingRegions: false,
  loadingHotels: false,
  loadingRoomTypes: false,
  error: null,
};

const hotelSearchSlice = createSlice({
  name: "hotelSearch",
  initialState,
  reducers: {
    setCountries: (state, action) => {
      state.countries = action.payload || [];
      state.loadingCountries = false;
      if (!state.selectedCountry) {
        const costarica = state.countries.find((c) => c.name === "Costa Rica");
        if (costarica) state.selectedCountry = costarica.id;
      }
    },
    setLoadingCountries: (state, action) => {
      state.loadingCountries = action.payload;
    },

    setRegions: (state, action) => {
      state.regions = action.payload || [];
      state.loadingRegions = false;
    },
    setLoadingRegions: (state, action) => {
      state.loadingRegions = action.payload;
    },

    setHotels: (state, action) => {
      state.hotels = action.payload || [];
      state.loadingHotels = false;
    },
    setLoadingHotels: (state, action) => {
      state.loadingHotels = action.payload;
    },

    setRoomTypes: (state, action) => {
      state.roomTypes = action.payload || [];
      state.loadingRoomTypes = false;
    },
    setLoadingRoomTypes: (state, action) => {
      state.loadingRoomTypes = action.payload;
    },

    setBaselineRates: (state, action) => {
      state.baselineRates = action.payload || [];
      state.maxOccupancy = action.payload.length > 0 ? action.payload[0].max_total_occupancy || null : null;
    },

    setGuestCharges: (state, action) => {
      state.guestCharges = action.payload || [];
    },

    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
    setSelectedRegion: (state, action) => {
      state.selectedRegion = action.payload;
    },
    setSelectedHotel: (state, action) => {
      state.selectedHotel = action.payload;
    },
    setSelectedRoomType: (state, action) => {
      state.selectedRoomType = action.payload;
    },
    setDates: (state, action) => {
      state.selectedDates = action.payload;
    },

    setNumAdults: (state, action) => {
      state.numAdults = action.payload;
    },
    setNumChildren: (state, action) => {
      state.numChildren = action.payload;
    },
    setChildrenAges: (state, action) => {
      state.childrenAges = action.payload;
    },

    setAvailableDates: (state, action) => {
      state.availableDates = action.payload || [];
      state.lastAvailableDate = action.payload && action.payload.length > 0 ? action.payload[action.payload.length - 1] : null;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setCountries,
  setLoadingCountries,
  setRegions,
  setLoadingRegions,
  setHotels,
  setLoadingHotels,
  setRoomTypes,
  setLoadingRoomTypes,
  setAvailableDates,
  setBaselineRates,
  setGuestCharges,
  setSelectedCountry,
  setSelectedRegion,
  setSelectedHotel,
  setSelectedRoomType,
  setDates,
  setNumAdults,
  setNumChildren,
  setChildrenAges,
  setError,
} = hotelSearchSlice.actions;

export const fetchCountries = () => ({ type: "hotelSearch/fetchCountries" });
export const fetchRegions = (countryId) => ({ type: "hotelSearch/fetchRegions", payload: countryId });
export const fetchHotels = (regionId) => ({ type: "hotelSearch/fetchHotels", payload: regionId });
export const fetchRoomTypes = (hotelId) => ({ type: "hotelSearch/fetchRoomTypes", payload: hotelId });
export const fetchDateRanges = (hotelId) => ({ type: "hotelSearch/fetchDateRanges", payload: hotelId });
export const fetchItemizedRates = (roomTypeId, startDate, endDate) => ({
  type: "hotelSearch/fetchItemizedRates",
  payload: { roomTypeId, startDate, endDate },
});
export const fetchAvailableDates = (hotelId) => ({ type: "hotelSearch/fetchAvailableDates", payload: hotelId });

export default hotelSearchSlice.reducer;