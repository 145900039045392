// File: src/components/Transfers/TransfersFrontpage.js
// Purpose: Entry point for the Transfers module, now integrated with Redux for state management.

import React from 'react';
import TransfersSearchBar from './TransfersSearchBar';
import RateDisplay from './RateDisplay';
import styles from 'styles/Transfers.module.css';

const TransfersFrontpage = () => {
  return (
    <div className={styles.transfersFrontpage}>
      <div className={styles.transfersSearchBar}>
        <TransfersSearchBar />
      </div>
      <RateDisplay />
    </div>
  );
};

export default TransfersFrontpage;