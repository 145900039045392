// File: src/components/Dashboard/HotelRatesSearch/HotelPolicy.js
// Purpose: Displays hotel policies including name, description, and an optional PDF link.

import React from "react";
import { useSelector } from "react-redux";
import { selectSelectedHotelDetails } from "../../../redux/selectors/hotelsSelectors";
import styles from "styles/HotelRatesSearch.module.css";

const HotelPolicy = () => {
  const hotelDetails = useSelector(selectSelectedHotelDetails);
  const hotelName = hotelDetails ? hotelDetails.name : "No hotel selected";

  return (
    <div className={styles.policyContainer}>
      <h2>{hotelName} Policies</h2>
      {hotelDetails ? (
        <>
          {hotelDetails.description ? (
            <div
              className={styles.policyDescription}
              dangerouslySetInnerHTML={{ __html: hotelDetails.description }}
            />
          ) : (
            <p>No policies available.</p>
          )}
          {hotelDetails.pdf ? (
            <p className={styles.pdfLink}>
              <a
                href={hotelDetails.pdf}
                target="_blank"
                rel="noopener noreferrer"
              >
                View PDF
              </a>
            </p>
          ) : (
            <p>No PDF available.</p>
          )}
        </>
      ) : (
        <p>No hotel selected.</p>
      )}
    </div>
  );
};

export default HotelPolicy;