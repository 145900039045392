// File: src/components/Databoard/LogViewer.js
import React, { useState, useRef } from "react";

const LogViewer = () => {
  const [logs, setLogs] = useState([]);
  const [filter, setFilter] = useState("All"); // Filter state
  const logContainerRef = useRef(null);

  const addLog = (category, message, details = null) => {
    const timestamp = new Date().toISOString();
    const logEntry = { timestamp, category, message, details };
    setLogs((prevLogs) => [...prevLogs, logEntry]);

    // Scroll to the bottom of the log viewer
    if (logContainerRef.current) {
      setTimeout(() => {
        logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight;
      }, 0);
    }

    console.log(`[${timestamp}] (${category}): ${message}`, details || "");
  };

  // Clears all logs
  const clearLogs = () => {
    setLogs([]);
  };

  // Copies logs to the clipboard
  const copyLogsToClipboard = () => {
    const logText = logs
      .map(
        (log) =>
          `[${log.timestamp}] (${log.category}): ${log.message}${
            log.details ? `\nDetails: ${JSON.stringify(log.details, null, 2)}` : ""
          }`
      )
      .join("\n\n");

    navigator.clipboard.writeText(logText).then(
      () => alert("Logs copied to clipboard!"),
      (err) => alert("Failed to copy logs to clipboard: " + err)
    );
  };

  // Ensure this `addLog` takes priority and cannot be overwritten
  if (!window.LogViewer) {
    window.LogViewer = {}; // Create namespace if it doesn't exist
  }

  if (!window.LogViewer.addLog) {
    // Define addLog with protection
    Object.defineProperty(window.LogViewer, "addLog", {
      value: addLog,
      writable: false, // Prevent overwriting
      configurable: false, // Prevent reconfiguration
    });
  } else {
    console.warn("LogViewer.addLog is already defined and cannot be overwritten.");
  }

  // Filtered logs based on selected category
  const filteredLogs = logs.filter((log) => filter === "All" || log.category === filter);

  return (
    <div style={{ padding: "10px", backgroundColor: "#f4f4f4", border: "1px solid #ddd" }}>
      <h3>Log Viewer</h3>
      <div style={{ marginBottom: "10px" }}>
        <button onClick={clearLogs} style={{ marginRight: "10px" }}>
          Clear Logs
        </button>
        <button onClick={copyLogsToClipboard} style={{ marginRight: "10px" }}>
          Copy Logs
        </button>
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          style={{ padding: "5px", border: "1px solid #ccc" }}
        >
          <option value="All">All</option>
          <option value="Slice">Slice</option>
          <option value="Saga">Saga</option>
          <option value="API">API</option>
          <option value="Selectors">Selectors</option>
        </select>
      </div>
      <div
        ref={logContainerRef}
        style={{
          height: "300px",
          overflowY: "scroll",
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          padding: "10px",
          fontFamily: "monospace",
          fontSize: "12px",
        }}
      >
        {filteredLogs.length === 0 ? (
          <div style={{ textAlign: "center", color: "#999" }}>No logs for the selected filter</div>
        ) : (
          filteredLogs.map((log, index) => (
            <div key={index} style={{ marginBottom: "10px" }}>
              <strong>[{log.timestamp}]</strong> <em>({log.category})</em>: {log.message}
              {log.details && (
                <pre style={{ backgroundColor: "#f9f9f9", padding: "5px", marginTop: "5px" }}>
                  {JSON.stringify(log.details, null, 2)}
                </pre>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

// Export the addLog function for local imports
export const addLog = (category, message, details = null) => {
  if (window.LogViewer && window.LogViewer.addLog) {
    window.LogViewer.addLog(category, message, details);
  }
};

export default LogViewer;