// File: src/components/Transfers/SelectYearDropdown.js
// Purpose: Dropdown component for selecting a year, integrated with Redux and selectors.

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'antd/lib/select';
import {
    selectYears,
    selectIsYearsLoading,
    selectSelectedYearId,
} from '../../../redux/selectors/transfersSelectors';
import { fetchYearsRequest, selectYear, fetchPickupLocationsRequest } from '../../../redux/slices/transfersSlice';
import styles from 'styles/Transfers.module.css';

const { Option } = Select;

const SelectYearDropdown = () => {
    const dispatch = useDispatch();

    const years = useSelector(selectYears);
    const loading = useSelector(selectIsYearsLoading);
    const selectedYearId = useSelector(selectSelectedYearId);

    useEffect(() => {
        dispatch(fetchYearsRequest());
    }, [dispatch]);

    useEffect(() => {
        if (years.length > 0 && !selectedYearId) {
            const year2025 = years.find(year => Number(year.year) === 2025 || Number(year.id) === 2025);

            console.log("Checking auto-selection:", { years, selectedYearId, year2025 });

            if (year2025) {
                dispatch(selectYear(year2025.id));
                dispatch(fetchPickupLocationsRequest(year2025.id)); // Auto-trigger pickup locations API
            }
        }
    }, [years, dispatch, selectedYearId]);

    const handleYearSelect = (value) => {
        dispatch(selectYear(value));
        dispatch(fetchPickupLocationsRequest(value)); // Trigger pickup locations when user selects a year
    };

    return (
        <Select
            className={styles.reactSelectControl}
            value={selectedYearId || null}
            onChange={handleYearSelect}
            placeholder="Select a year"
            allowClear
            style={{
                width: '100%',
                minWidth: '110px',
            }}
            notFoundContent={loading ? "Loading..." : "No data available"}
        >
            {years.map((year) => (
                <Option key={year.id} value={year.id}>
                    {year.year}
                </Option>
            ))}
        </Select>
    );
};

export default SelectYearDropdown;
