// File: src/services/hotelSearchApiService.js
// Purpose: Handles all API requests for hotel search, rates, and additional guest charges.

import axiosInstance from "./axios";

/**
 * Fetches the list of countries.
 * @returns {Promise<Array>} An array of country objects.
 */
export const fetchCountries = async () => {
    try {
        const response = await axiosInstance.get("/hotel-quotes/countries/");
        return response.data.results || []; // ✅ Returns raw API response
    } catch (error) {
        console.error("Error fetching countries:", error);
        throw error;
    }
};

/**
 * Fetches the list of regions based on a selected country.
 * @param {string} countryId - The ID of the selected country.
 * @returns {Promise<Array>} An array of region objects.
 */
export const fetchRegions = async (countryId) => {
    try {
        const response = await axiosInstance.get("/hotel-quotes/regions/", {
            params: { country: countryId }, // ✅ Matches backend parameter
        });
        return response.data.results || []; // ✅ Returns raw API response
    } catch (error) {
        console.error("Error fetching regions:", error);
        throw error;
    }
};

/**
 * Fetches the list of hotels based on a selected region.
 * @param {string} regionId - The ID of the selected region.
 * @returns {Promise<Array>} An array of hotel objects.
 */
export const fetchHotels = async (regionId) => {
    try {
        const response = await axiosInstance.get("/hotel-quotes/hotels/", {
            params: { region: regionId }, // ✅ Matches backend parameter
        });
        return response.data.results || []; // ✅ Returns raw API response
    } catch (error) {
        console.error("Error fetching hotels:", error);
        throw error;
    }
};

/**
 * Fetches the list of room types for a selected hotel.
 * @param {string} hotelId - The ID of the selected hotel.
 * @returns {Promise<Array>} An array of room type objects.
 */
export const fetchRoomTypes = async (hotelId) => {
    try {
        const response = await axiosInstance.get("/hotel-quotes/room-types/", {
            params: { hotel: hotelId }, // ✅ Matches backend parameter
        });
        return response.data.results || []; // ✅ Returns raw API response
    } catch (error) {
        console.error("Error fetching room types:", error);
        throw error;
    }
};

/**
 * Fetches itemized daily rates for a given room type within a specified date range.
 * @param {number} roomTypeId - The ID of the selected room type.
 * @param {string} startDate - The start date (YYYY-MM-DD).
 * @param {string} endDate - The end date (YYYY-MM-DD).
 * @returns {Promise<Array>} A list of itemized daily rates with guest charges.
 */
export const fetchItemizedRates = async (roomTypeId, startDate, endDate) => {
    if (!roomTypeId) {
        console.error("Error: Missing room type ID in API call.");
        throw new Error("Room type ID is required to fetch itemized rates.");
    }

    try {
        const response = await axiosInstance.get("/hotel-quotes/baseline-rates/", {
            params: { 
                room_type: roomTypeId,  // ✅ Ensures backend receives correct room type parameter
                start_date: startDate, 
                end_date: endDate 
            }, 
        });

        return response.data || []; // ✅ Ensures API response consistency
    } catch (error) {
        console.error("Error fetching itemized rates:", error);
        throw error;
    }
};

/**
 * Fetches available date ranges for a given hotel.
 * @param {number} hotelId - The ID of the selected hotel.
 * @returns {Promise<Array>} A list of available date ranges.
 */
export const fetchDateRanges = async (hotelId) => {
    try {
        const response = await axiosInstance.get("/hotel-quotes/date-ranges/", {
            params: { hotel: hotelId }, // ✅ Matches backend parameter
        });

        return response.data.results || []; // ✅ Returns raw API response
    } catch (error) {
        console.error("Error fetching date ranges:", error);
        throw error;
    }
};

/**
 * Fetches available dates for a given hotel.
 * @param {number} hotelId - The ID of the selected hotel.
 * @returns {Promise<Array>} A list of available dates as strings (YYYY-MM-DD).
 */
export const fetchAvailableDates = async (hotelId) => {
    try {
        const response = await axiosInstance.get(`/hotel-quotes/available-dates/${hotelId}/`);
        return response.data.available_dates || [];
    } catch (error) {
        console.error("Error fetching available dates:", error);
        return [];
    }
};