// File: src/components/Databoard/DataboardLayout.js
// Purpose: Main layout component for the Databoard, including the sidebar and content area.

import React from "react";
import { Layout } from "antd";
import { Outlet } from "react-router-dom"; // Import Outlet for child routes
import Sidebar from "./Sidebar";
import "./Databoard.module.css"; // Scoped CSS for Databoard

const { Header, Sider, Content } = Layout;

const DataboardLayout = () => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header className="databoard-header">Databoard</Header>
      <Layout>
        <Sider>
          <Sidebar />
        </Sider>
        <Content className="databoard-content">
          <Outlet /> {/* Dynamically renders child components */}
        </Content>
      </Layout>
    </Layout>
  );
};

export default DataboardLayout;