// File: src/redux/selectors/hotelsSelectors.js
// Location: src/redux/selectors/hotelsSelectors.js
// Purpose: Provides selectors for hotel-related state management. Selections are stored as IDs,
// and these selectors derive the full objects from the lists in state.

import { createSelector } from "reselect";

// Basic list selectors
export const selectCountries = (state) => state.hotelSearch.countries;
export const selectRegions = (state) => state.hotelSearch.regions;
export const selectHotels = (state) => state.hotelSearch.hotels;
export const selectRoomTypes = (state) => state.hotelSearch.roomTypes;
export const selectAvailableDateRanges = (state) => state.hotelSearch.availableDateRanges;
export const selectBaselineRates = (state) => state.hotelSearch.baselineRates;
export const selectGuestCharges = (state) => state.hotelSearch.guestCharges;

// Selections are now stored as IDs
export const selectSelectedCountryId = (state) => state.hotelSearch.selectedCountry;
export const selectSelectedRegionId = (state) => state.hotelSearch.selectedRegion;
export const selectSelectedHotelId = (state) => state.hotelSearch.selectedHotel;
export const selectSelectedRoomTypeId = (state) => state.hotelSearch.selectedRoomType;
export const selectSelectedDates = (state) => state.hotelSearch.selectedDates;
export const selectNumAdults = (state) => state.hotelSearch.numAdults;
export const selectNumChildren = (state) => state.hotelSearch.numChildren;
export const selectChildrenAges = (state) => state.hotelSearch.childrenAges;
export const selectLoading = (state) => state.hotelSearch.loading;
export const selectError = (state) => state.hotelSearch.error;

// Derived selectors: Lookup full objects using stored IDs

export const selectSelectedCountryDetails = createSelector(
  [selectCountries, selectSelectedCountryId],
  (countries, selectedCountryId) =>
    countries.find((country) => country.id === selectedCountryId) || null
);

export const selectSelectedRegionDetails = createSelector(
  [selectRegions, selectSelectedRegionId],
  (regions, selectedRegionId) =>
    regions.find((region) => region.id === selectedRegionId) || null
);

export const selectSelectedHotelDetails = createSelector(
  [selectHotels, selectSelectedHotelId],
  (hotels, selectedHotelId) =>
    hotels.find((hotel) => hotel.id === selectedHotelId) || null
);

export const selectSelectedRoomTypeDetails = createSelector(
  [selectRoomTypes, selectSelectedRoomTypeId],
  (roomTypes, selectedRoomTypeId) =>
    roomTypes.find((roomType) => roomType.id === selectedRoomTypeId) || null
);

// Example: Combine hotel pricing details based on the selected room type.
export const selectHotelPricingDetails = createSelector(
  [selectBaselineRates, selectSelectedRoomTypeId],
  (baselineRates, selectedRoomTypeId) => {
    const rates = selectedRoomTypeId
      ? baselineRates.filter((rate) => rate.roomTypeId === selectedRoomTypeId)
      : [];
    // Optionally, merge guest charges or additional details as needed.
    return { rates };
  }
);