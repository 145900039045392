// File: src/services/transfers_api.js
// Purpose: Provides API services for managing transfer-related data using a centralized Axios instance.

import axiosInstance from "./axios"; // Use the shared Axios instance

// Fetch all available years
export const fetchAvailableYears = () => axiosInstance.get('/transfers/years/');

// Fetch pickup locations by year ID
export const fetchPickupLocationsByYearId = (yearId) =>
  axiosInstance.get(`/transfers/pickup-locations/?year_id=${yearId}`);

// Fetch dropoff locations by pickup location ID
export const fetchDropOffLocationsByPickup = (pickupId) =>
  axiosInstance.get(`/transfers/dropoff-locations/?pickup_location_id=${pickupId}`);

// Fetch rates by year, pickup location, and dropoff location IDs
export const fetchRates = (yearId, pickupLocationId, dropoffLocationId) =>
  axiosInstance.get(`/transfers/rates/`, {
    params: {
      year_id: yearId,
      pickup_location_id: pickupLocationId,
      dropoff_location_id: dropoffLocationId,
    },
  });

// Create a new transfer
export const createTransfer = (transferData) =>
  axiosInstance.post('/transfers/rates/', transferData);

// Update an existing transfer
export const updateTransfer = (id, updatedData) =>
  axiosInstance.put(`/transfers/rates/${id}/`, updatedData);

// Delete a transfer
export const deleteTransfer = (id) =>
  axiosInstance.delete(`/transfers/rates/${id}/`);

export default {
  fetchAvailableYears,
  fetchPickupLocationsByYearId,
  fetchDropOffLocationsByPickup,
  fetchRates,
  createTransfer,
  updateTransfer,
  deleteTransfer,
};