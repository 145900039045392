// File: src/redux/sagas/hotelSearchSaga.js

import { call, put, takeLatest, select, fork } from "redux-saga/effects";
import {
  fetchCountries as apiFetchCountries,
  fetchRegions as apiFetchRegions,
  fetchHotels as apiFetchHotels,
  fetchRoomTypes as apiFetchRoomTypes,
  fetchDateRanges as apiFetchDateRanges,
  fetchItemizedRates as apiFetchItemizedRates,
  fetchAvailableDates as apiFetchAvailableDates
} from "../../services/hotelSearchApiService";
import {
  setCountries,
  setLoadingCountries,
  setRegions,
  setLoadingRegions,
  setHotels,
  setLoadingHotels,
  setRoomTypes,
  setLoadingRoomTypes,
  setDates,
  setBaselineRates,
  setAvailableDates,
  setError
} from "../slices/hotelSearchSlice";
import { transformDateRanges, transformDailyRates } from "../transformers/hotelSearchTransformer";

const getHotelSearchState = (state) => state.hotelSearch;

function* handleFetchCountries() {
  try {
    yield put(setLoadingCountries(true));
    const response = yield call(apiFetchCountries);
    yield put(setCountries(response));
  } catch (error) {
    yield put(setError(error.message));
  } finally {
    yield put(setLoadingCountries(false));
  }
}

function* handleFetchRegions(action) {
  try {
    yield put(setLoadingRegions(true));
    const response = yield call(apiFetchRegions, action.payload);
    yield put(setRegions(response));
  } catch (error) {
    yield put(setError(error.message));
  } finally {
    yield put(setLoadingRegions(false));
  }
}

function* handleFetchHotels(action) {
  try {
    yield put(setLoadingHotels(true));
    const response = yield call(apiFetchHotels, action.payload);
    yield put(setHotels(response));
  } catch (error) {
    yield put(setError(error.message));
  } finally {
    yield put(setLoadingHotels(false));
  }
}

function* handleFetchRoomTypes(action) {
  try {
    yield put(setLoadingRoomTypes(true));
    const response = yield call(apiFetchRoomTypes, action.payload);
    yield put(setRoomTypes(response));
  } catch (error) {
    yield put(setError(error.message));
  } finally {
    yield put(setLoadingRoomTypes(false));
  }
}

function* handleFetchDateRanges(action) {
  try {
    const response = yield call(apiFetchDateRanges, action.payload);
    yield put(setDates(transformDateRanges(response)));
  } catch (error) {
    yield put(setError(error.message));
  }
}

function* handleFetchItemizedRates(action) {
  try {
    const { roomTypeId, startDate, endDate } = action.payload;
    const response = yield call(apiFetchItemizedRates, roomTypeId, startDate, endDate);
    yield put(setBaselineRates(transformDailyRates(response)));
  } catch (error) {
    yield put(setError(error.message));
  }
}

function* handleFetchAvailableDates(action) {
  try {
    const availableDates = yield call(apiFetchAvailableDates, action.payload);
    yield put(setAvailableDates(availableDates));
  } catch (error) {
    yield put(setError(error.message));
  }
}

function* handleCheckAndFetchRates() {
  const state = yield select(getHotelSearchState);
  const { selectedRoomType, selectedDates } = state;

  if (selectedRoomType && selectedDates.startDate && selectedDates.endDate) {
    try {
      const response = yield call(
        apiFetchItemizedRates,
        selectedRoomType,
        selectedDates.startDate,
        selectedDates.endDate
      );
      yield put(setBaselineRates(transformDailyRates(response)));
    } catch (error) {
      yield put(setError(error.message));
    }
  }
}

export function* watchForRateFetch() {
  yield takeLatest("hotelSearch/setSelectedRoomType", handleCheckAndFetchRates);
  yield takeLatest("hotelSearch/setDates", handleCheckAndFetchRates);
}

export function* watchHotelSearch() {
  yield takeLatest("hotelSearch/fetchCountries", handleFetchCountries);
  yield takeLatest("hotelSearch/fetchRegions", handleFetchRegions);
  yield takeLatest("hotelSearch/fetchHotels", handleFetchHotels);
  yield takeLatest("hotelSearch/fetchRoomTypes", handleFetchRoomTypes);
  yield takeLatest("hotelSearch/fetchDateRanges", handleFetchDateRanges);
  yield takeLatest("hotelSearch/fetchItemizedRates", handleFetchItemizedRates);
  yield takeLatest("hotelSearch/fetchAvailableDates", handleFetchAvailableDates);
}

export function* watchHotelSearchSagas() {
  yield fork(watchHotelSearch);
  yield fork(watchForRateFetch);
}