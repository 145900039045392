// File: src/components/Databoard/Modules/Transfers/UnifiedTransfersPage.js
// Purpose: Combined page with form and table for managing Transfers.

import React from "react";
import UnifiedTransfersForm from "./UnifiedTransfersPage";
import TransfersTable from "./TransfersTable";
import "./Transfers.module.css";

const UnifiedTransfersPage = () => {
  return (
    <div className="unified-transfers-page">
      <h1 className="page-title">Transfers Management</h1>
      <UnifiedTransfersForm />
      <TransfersTable />
    </div>
  );
};

export default UnifiedTransfersPage;