// File: src/services/DataboardTransfersAPI.js
// Purpose: Provides CRUD operations and data fetching for Databoard Transfers using a centralized Axios instance.

import axiosInstance from "./axios"; // Shared Axios instance for API requests

// Fetch all available years
export const fetchAvailableYears = () =>
  axiosInstance.get('/transfers/years/');

// Fetch pickup locations by year ID
export const fetchPickupLocationsByYearId = (yearId) =>
  axiosInstance.get(`/transfers/pickup-locations/?year_id=${yearId}`);

// Fetch dropoff locations by pickup location ID
export const fetchDropOffLocationsByPickup = (pickupId) =>
  axiosInstance.get(`/transfers/dropoff-locations/?pickup_location_id=${pickupId}`);

// Fetch rates by year, pickup location, and dropoff location IDs
export const fetchRates = (yearId, pickupLocationId, dropoffLocationId) =>
  axiosInstance.get('/transfers/rates/', {
    params: {
      year_id: yearId,
      pickup_location_id: pickupLocationId,
      dropoff_location_id: dropoffLocationId,
    },
  });

// Create a new year
export const createYear = (yearData) =>
  axiosInstance.post('/transfers/years/', yearData);

// Create a new transfer
export const createTransfer = (transferData) =>
  axiosInstance.post('/transfers/rates/', transferData);

// Update an existing transfer
export const updateTransfer = (id, updatedData) =>
  axiosInstance.put(`/transfers/rates/${id}/`, updatedData);

// Delete a transfer
export const deleteTransfer = (id) =>
  axiosInstance.delete(`/transfers/rates/${id}/`);

// Fetch a single transfer detail by ID
export const fetchTransferById = (id) =>
  axiosInstance.get(`/transfers/rates/${id}/`);

// Bulk update transfers
export const bulkUpdateTransfers = (updates) =>
  axiosInstance.put('/transfers/rates/bulk/', updates);

// Fetch statistics or aggregated data for transfers
export const fetchTransferStats = () =>
  axiosInstance.get('/transfers/rates/stats/');

export default {
  fetchAvailableYears,
  fetchPickupLocationsByYearId,
  fetchDropOffLocationsByPickup,
  fetchRates,
  createYear,
  createTransfer,
  updateTransfer,
  deleteTransfer,
  fetchTransferById,
  bulkUpdateTransfers,
  fetchTransferStats,
};