// File: src/redux/sagas/databoardTransfersSaga.js
// Purpose: Handles side effects for the Transfers module, including fetching and managing data.

import { call, put, takeLatest, all } from "redux-saga/effects";
import {
  fetchAvailableYears,
  fetchPickupLocationsByYearId,
  fetchDropOffLocationsByPickup,
  fetchRates,
  createYear,
  createTransfer,
} from "services/DataboardTransfersAPI";
import {
  fetchYearsRequest,
  fetchYearsSuccess,
  fetchYearsFailure,
  fetchPickupLocationsRequest,
  fetchPickupLocationsSuccess,
  fetchPickupLocationsFailure,
  fetchDropoffLocationsRequest,
  fetchDropoffLocationsSuccess,
  fetchDropoffLocationsFailure,
  fetchTransfersRequest,
  fetchTransfersSuccess,
  fetchTransfersFailure,
} from "components/Databoard/modules/Transfers/databoardTransfersSlice";
import {
  normalizeYears,
  normalizePickupLocations,
  normalizeDropoffLocations,
  normalizeTransfers,
} from "./databoardTransfersTransformers";
import { addLog } from "../../LogViewer";

// Worker saga for fetching years
function* handleFetchYears() {
  try {
    addLog("Saga", "Fetching years started");
    const response = yield call(fetchAvailableYears);
    const normalizedYears = normalizeYears(response.data);
    yield put(fetchYearsSuccess(normalizedYears));
    addLog("Saga", "Fetching years succeeded");
  } catch (error) {
    addLog("Saga", "Error fetching years", error.message);
    yield put(fetchYearsFailure(error.message || "Failed to fetch years."));
  }
}

// Worker saga for fetching pickup locations
function* handleFetchPickupLocations(action) {
  try {
    addLog("Saga", `Fetching pickup locations for year: ${action.payload}`);
    const response = yield call(fetchPickupLocationsByYearId, action.payload);
    const normalizedPickupLocations = normalizePickupLocations(response.data);
    yield put(fetchPickupLocationsSuccess(normalizedPickupLocations));
    addLog("Saga", "Fetching pickup locations succeeded");
  } catch (error) {
    addLog("Saga", "Error fetching pickup locations", error.message);
    yield put(fetchPickupLocationsFailure(error.message || "Failed to fetch pickup locations."));
  }
}

// Worker saga for fetching dropoff locations
function* handleFetchDropoffLocations(action) {
  try {
    addLog("Saga", `Fetching dropoff locations for pickup: ${action.payload}`);
    const response = yield call(fetchDropOffLocationsByPickup, action.payload);
    const normalizedDropoffLocations = normalizeDropoffLocations(response.data);
    yield put(fetchDropoffLocationsSuccess(normalizedDropoffLocations));
    addLog("Saga", "Fetching dropoff locations succeeded");
  } catch (error) {
    addLog("Saga", "Error fetching dropoff locations", error.message);
    yield put(fetchDropoffLocationsFailure(error.message || "Failed to fetch dropoff locations."));
  }
}

// Worker saga for fetching transfers
function* handleFetchTransfers(action) {
  try {
    addLog("Saga", "Fetching transfers started", action.payload);
    const { page, yearId, pickupLocationId, dropoffLocationId } = action.payload;
    const response = yield call(fetchRates, yearId, pickupLocationId, dropoffLocationId, page);
    const normalizedTransfers = normalizeTransfers(response.data.results);
    yield put(
      fetchTransfersSuccess({
        transfers: normalizedTransfers,
        pagination: response.data.pagination,
      })
    );
    addLog("Saga", "Fetching transfers succeeded");
  } catch (error) {
    addLog("Saga", "Error fetching transfers", error.message);
    yield put(fetchTransfersFailure(error.message || "Failed to fetch transfers."));
  }
}

// Worker saga for creating a new year
function* handleCreateYear(action) {
  try {
    addLog("Saga", "Creating a new year started", action.payload);
    const response = yield call(createYear, action.payload);
    const normalizedYears = normalizeYears([response.data]); // Normalize single year
    yield put(fetchYearsSuccess(normalizedYears));
    addLog("Saga", "Year created successfully");
  } catch (error) {
    addLog("Saga", "Error creating year", error.message);
    yield put(fetchYearsFailure(error.message || "Failed to create year."));
  }
}

// Worker saga for creating a new transfer
function* handleCreateTransfer(action) {
  try {
    addLog("Saga", "Creating a new transfer started", action.payload);
    const response = yield call(createTransfer, action.payload);
    const normalizedTransfers = normalizeTransfers([response.data]); // Normalize single transfer
    yield put(fetchTransfersSuccess({ transfers: normalizedTransfers, pagination: {} }));
    addLog("Saga", "Transfer created successfully");
  } catch (error) {
    addLog("Saga", "Error creating transfer", error.message);
    yield put(fetchTransfersFailure(error.message || "Failed to create transfer."));
  }
}

// Watcher sagas
function* watchFetchYears() {
  yield takeLatest(fetchYearsRequest.type, handleFetchYears);
}

function* watchFetchPickupLocations() {
  yield takeLatest(fetchPickupLocationsRequest.type, handleFetchPickupLocations);
}

function* watchFetchDropoffLocations() {
  yield takeLatest(fetchDropoffLocationsRequest.type, handleFetchDropoffLocations);
}

function* watchFetchTransfers() {
  yield takeLatest(fetchTransfersRequest.type, handleFetchTransfers);
}

function* watchCreateYear() {
  yield takeLatest("databoardTransfers/createYear", handleCreateYear);
}

function* watchCreateTransfer() {
  yield takeLatest("databoardTransfers/createTransfer", handleCreateTransfer);
}

// Root saga
export default function* databoardTransfersSaga() {
  yield all([
    watchFetchYears(),
    watchFetchPickupLocations(),
    watchFetchDropoffLocations(),
    watchFetchTransfers(),
    watchCreateYear(),
    watchCreateTransfer(),
  ]);
}