// File: src/redux/store.js
// Purpose: Configures the Redux store with reducers and middleware for the project, including hotels, tours, domestic flights, and Databoard Transfers functionality.

import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import toursDataReducer from './slices/toursDataSlice';
import toursOperationsReducer from './slices/toursOperationsSlice';
import domesticFlightsDataReducer from './slices/domesticFlightsDataSlice'; // Domestic Flights Data Slice
import domesticFlightsOperationsReducer from './slices/domesticFlightsOperationsSlice'; // Domestic Flights Operations Slice
import databoardTransfersReducer from '../components/Databoard/modules/Transfers/databoardTransfersSlice'; // Databoard Transfers Slice
import transfersReducer from './slices/transfersSlice'; // Transfers Slice
import hotelSearchReducer from './slices/hotelSearchSlice'; // ✅ Retained Hotel Search Slice
import toursMiddleware from './middleware/toursMiddleware';
import domesticFlightsDataListenerMiddleware from './middleware/domesticFlightsDataMiddleware'; // Updated to Listener Middleware
import domesticFlightsOperationsListenerMiddleware from './middleware/domesticFlightsOperationsMiddleware'; // Updated to Listener Middleware
import { watchHotelSearchSagas } from "./sagas/hotelSearchSaga";
import { all } from "redux-saga/effects";
import rootSaga from './sagas/rootSaga';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        toursData: toursDataReducer,
        toursOperations: toursOperationsReducer,
        domesticFlightsData: domesticFlightsDataReducer,
        domesticFlightsOperations: domesticFlightsOperationsReducer,
        databoardTransfers: databoardTransfersReducer, // Existing Databoard Transfers
        transfers: transfersReducer, // Existing Transfers Reducer
        hotelSearch: hotelSearchReducer, // ✅ Hotel Search remains intact
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            sagaMiddleware, // Add Saga Middleware
            toursMiddleware,
            domesticFlightsDataListenerMiddleware.middleware,
            domesticFlightsOperationsListenerMiddleware.middleware
        ),
    devTools: process.env.NODE_ENV !== 'production', // Enables Redux DevTools in development
});

sagaMiddleware.run(rootSaga);

export default store;
