// File: src/components/Dashboard/HotelRatesSearch/HotelRatesCalculator.js
// Purpose: Handles all mathematical calculations for hotel quotes.

export const computeRates = (baselineRates, numAdults, childrenAges) => {
  let grandTotalNet = 0;
  let grandTotalRack = 0;

  if (!baselineRates || !numAdults || !childrenAges) {
    return { dailyRates: [], grandTotalNet: 0, grandTotalRack: 0 };
  }

  const dailyRates = baselineRates.map((rate) => {
    const baselineNet = parseFloat(rate.net_rate_with_taxes);
    const baselineRack = parseFloat(rate.rack_rate_with_taxes);
    const baselineOccupancy = rate.baseline_occupancy;

    
    // Sort children by age in descending order (oldest first)
    const sortedChildrenAges = [...childrenAges].sort((a, b) => b - a);
    
    // Assign the oldest children first to the baseline slots
    const chargeableChildren = sortedChildrenAges.slice(baselineOccupancy - numAdults);

    const extraAdults = Math.max(0, numAdults - baselineOccupancy);
    const adultCharge = rate.guest_charges.find(
      (gc) => 18 >= gc.min_age && 18 <= gc.max_age
    );
    const extraAdultNet = adultCharge ? parseFloat(adultCharge.net_rate_with_taxes) * extraAdults : 0;
    const extraAdultRack = adultCharge ? parseFloat(adultCharge.rack_rate_with_taxes) * extraAdults : 0;

    let childrenNetTotal = 0;
    let childrenRackTotal = 0;

    chargeableChildren.forEach((childAge) => {
      const childCharge = rate.guest_charges.find(
        (gc) => childAge >= gc.min_age && childAge <= gc.max_age
      );
      if (childCharge) {
        childrenNetTotal += parseFloat(childCharge.net_rate_with_taxes);
        childrenRackTotal += parseFloat(childCharge.rack_rate_with_taxes);
      }
    });

    const totalNet = baselineNet + extraAdultNet + childrenNetTotal;
    const totalRack = baselineRack + extraAdultRack + childrenRackTotal;

    grandTotalNet += totalNet;
    grandTotalRack += totalRack;

    return {
      date: rate.date,
      baselineNet,
      baselineRack,
      baselineOccupancy,
      maxTotalOccupancy: rate.max_total_occupancy,
      extraAdultNet,
      extraAdultRack,
      childrenNetTotal,
      childrenRackTotal,
      totalNet,
      totalRack,
    };
  });

  return { dailyRates, grandTotalNet, grandTotalRack };
};

export const computeFullStayTotals = (baselineRates, numAdults, childrenAges) => {
  const { grandTotalNet, grandTotalRack } = computeRates(baselineRates, numAdults, childrenAges);
  return { grandTotalNet, grandTotalRack };
};
