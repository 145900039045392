// File: src/redux/transformers/transfersTransformers.js
// Purpose: Contains functions to transform API response data into the required format for Redux state.

export const transformYears = (years) => {
    return years.map((year) => ({
        id: year.id,
        year: year.year,
    }));
};

export const transformLocations = (locations, type) => {
    return locations.map((location) => ({
        id: location.id,
        name: type === 'pickup' ? location.pickup_location : location.dropoff_location,
    }));
};

export const transformRates = (rates) => {
    return rates.map((rate) => ({
        id: rate.id,
        groupSize: rate.group_size,
        netRateWithTaxes: rate.net_rate_with_taxes,
        rackRateWithTaxes: rate.rack_rate_with_taxes,
    }));
};

export default {
    transformYears,
    transformLocations,
    transformRates,
};