// File: src/components/LoginPage/Login.js
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'components/Common/AuthContext'; 
import 'styles/LoginPage.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const { login } = useContext(AuthContext); 
    const navigate = useNavigate();

    // State to determine which logo to display
    const [logo, setLogo] = useState('/henny_logo.png'); // Default to henny_logo

    // Determine the logo on component mount
    useEffect(() => {
        // Generate a random number between 1 and 10
        const randomNumber = Math.floor(Math.random() * 10) + 1;

        // Set the logo based on the random number (1/10 chance for bluefy_logo)
        if (randomNumber === 1) {
            setLogo('/bluefy_logo.png');
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage('');
        try {
            await login(username, password);
            setMessage('Login successful! Redirecting...');
            navigate('/dashboard');
        } catch (error) {
            setMessage('Login failed. Please check your credentials.');
        }
    };

    return (
        <div className="page-container">
            <div className="content-container">
                <div className="logo-container">
                    {/* Conditionally render the logo based on the determined state */}
                    <img src={logo} alt="Logo" className="login-logo" />
                </div>
                <div className="login-container">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Username:</label>
                            <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Password:</label>
                            <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                        </div>
                        <button type="submit" className="login-button">Login</button>
                    </form>
                    {message && <div className="login-message">{message}</div>}
                </div>
            </div>
        </div>
    );
}

export default Login;