// File: src/components/Common/DynamicDropdown.js
// Purpose: A reusable dropdown component for dynamically rendering options with optional search functionality.

import React from "react";
import { Select } from "antd";

const DynamicDropdown = ({
  label,
  data = [],
  selectedValue,
  onSelect,
  loading = false,
  disabled = false,
  placeholder = "Select an option",
  displayKey = "name",
  showSearch = false,
}) => {
  const options = Array.isArray(data) ? data : [];

  // Updated filterOption for better compatibility
  const filterOption = (input, option) => {
    const optionLabel = option?.label || "";
    return optionLabel.toLowerCase().includes(input.toLowerCase());
  };

  return (
    <div style={{ marginBottom: 16 }}>
      <label style={{ display: "block", marginBottom: 8 }}>{label}</label>
      <Select
        showSearch={showSearch}
        allowClear={showSearch}
        filterOption={showSearch ? filterOption : undefined}
        value={selectedValue || undefined}
        onChange={(value) => {
          onSelect(value);
        }}
        loading={loading}
        disabled={disabled}
        placeholder={loading ? "Fetching data..." : placeholder} // ✅ Show fetching message
        style={{ width: "100%" }}
        options={
          loading
            ? [{ value: "loading", label: "Fetching data...", disabled: true }] // ✅ Show fetching state
            : options.length > 0
            ? options.map((item) => ({
                value: item.id || item,
                label: item[displayKey] || item,
              }))
            : [{ value: "no-data", label: "No data available", disabled: true }] // ✅ Only show no data when loading is false
        }
      />
    </div>
  );
};

export default DynamicDropdown;