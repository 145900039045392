// File: src/redux/transformers/hotelSearchTransformer.js
// Purpose: Transforms backend API responses into frontend-friendly formats.

export const transformCountries = (countries) => {
    if (!Array.isArray(countries)) return [];
    return countries.map((country) => ({
      id: country.id,
      name: country.name,
    }));
  };
  
  export const transformRegions = (regions) => {
    if (!Array.isArray(regions)) return [];
    return regions.map((region) => ({
      id: region.id,
      name: region.name,
      countryId: region.country, // assuming API returns a country ID in "country"
    }));
  };
  
  export const transformHotels = (hotels) => {
    if (!Array.isArray(hotels)) return [];
    return hotels.map((hotel) => ({
      id: hotel.id,
      name: hotel.name,
      description: hotel.description, // include the description
      pdf: hotel.pdf,                 // include the PDF URL
      regionId: hotel.region,
      regionName: hotel.region_name,
      countryName: hotel.country_name,
    }));
  };
  
  export const transformRoomTypes = (roomTypes) => {
    if (!Array.isArray(roomTypes)) return [];
    return roomTypes.map((roomType) => ({
      id: roomType.id,
      name: roomType.type,
      hotelId: roomType.hotel,
    }));
  };
  
  /**
   * Transforms raw date ranges response into a structured format.
   * Also extracts the overall start and end dates for easy access.
   */
  export const transformDateRanges = (dateRanges) => {
    if (!Array.isArray(dateRanges) || dateRanges.length === 0) {
      return { start_date: null, end_date: null, dateRanges: [] };
    }
  
    const transformedRanges = dateRanges.map((dateRange) => ({
      id: dateRange.id,
      hotelId: dateRange.hotel,
      startDate: dateRange.start_date,
      endDate: dateRange.end_date,
      hotelName: dateRange.hotel_name,
    }));
  
    // Find the first start date and last end date
    const sortedByStart = [...transformedRanges].sort(
      (a, b) => new Date(a.startDate) - new Date(b.startDate)
    );
    const sortedByEnd = [...transformedRanges].sort(
      (a, b) => new Date(a.endDate) - new Date(b.endDate)
    );
  
    return {
      start_date: sortedByStart[0]?.startDate || null,
      end_date: sortedByEnd[sortedByEnd.length - 1]?.endDate || null,
      dateRanges: transformedRanges,
    };
  };
  
  /**
   * Transforms baseline rates response into a structured format.
   * Includes additional occupancy and pricing details.
   */
  export const transformBaselineRates = (baselineRates) => {
    if (!Array.isArray(baselineRates)) return [];
    return baselineRates.map((rate) => ({
      id: rate.id,
      roomTypeId: rate.room_type,  // Matches backend field name
      roomTypeName: rate.room_type_name,
      hotelName: rate.hotel_name,
      dateRange: rate.date_range_str, // Human-readable format
      netRate: parseFloat(rate.net_rate_with_taxes), // Ensures numerical consistency
      rackRate: parseFloat(rate.rack_rate_with_taxes),
      baselineOccupancy: rate.baseline_occupancy,
      maxOccupancy: rate.max_total_occupancy,
    }));
  };
  
  /**
   * Transforms additional guest charges into a structured format.
   * Ensures each charge is linked to a baseline rate.
   */
  export const transformAdditionalGuestCharges = (guestCharges) => {
    if (!Array.isArray(guestCharges)) return [];
    return guestCharges.map((charge) => ({
      id: charge.id,
      baselineRateId: charge.baseline_rate,
      roomTypeName: charge.room_type_name,
      hotelName: charge.hotel_name,
      dateRange: charge.date_range_str,
      minAge: charge.min_age,
      maxAge: charge.max_age,
      netRate: parseFloat(charge.net_rate_with_taxes),
      rackRate: parseFloat(charge.rack_rate_with_taxes),
    }));
  };
  
  /**
   * Transforms an array of daily rate objects (itemized baseline rates)
   * into a structured format that includes the date, rates, occupancy, and guest charges.
   */
  export const transformDailyRates = (dailyRates) => {
    if (!Array.isArray(dailyRates)) return [];
    return dailyRates.map((rate) => ({
      date: rate.date,
      net_rate_with_taxes: parseFloat(rate.net_rate_with_taxes),
      rack_rate_with_taxes: parseFloat(rate.rack_rate_with_taxes),
      baseline_occupancy: rate.baseline_occupancy,
      max_total_occupancy: rate.max_total_occupancy,
      guest_charges: rate.guest_charges || [],
    }));
  };