// File: src/components/Dashboard/HotelRatesSearch/HotelRatesTable.js
// Purpose: Fixes the date shift issue using Day.js and ensures correct date handling.

import React, { useMemo } from "react";
import dayjs from "dayjs";
import { computeRates } from "./HotelRatesCalculator";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc); // ✅ Enables UTC handling

// ✅ Fixes Date Shift Issue Using Day.js
const formatDate = (dateString) => {
  if (!dateString) return "";
  return dayjs.utc(dateString).format("DD-MMM-YYYY"); // ✅ Ensures UTC consistency
};

const HotelRatesTable = ({ baselineRates, numAdults, childrenAges }) => {
  // ✅ Move useMemo before any conditional return
  const tableData = useMemo(() => {
    if (!baselineRates || baselineRates.length === 0) return [];

    const { dailyRates, grandTotalNet, grandTotalRack } = computeRates(baselineRates, numAdults, childrenAges);

    const mappedRates = dailyRates.map((rate, index) => ({
      key: `${rate.date}-${index}`,
      formattedDate: formatDate(rate.date),
      baselineOccupancy: rate.baselineOccupancy,
      maxTotalOccupancy: rate.maxTotalOccupancy,
      ...rate,
    }));

    mappedRates.push({
      key: "total",
      formattedDate: "Total",
      baselineNet: 0,
      baselineRack: 0,
      baselineOccupancy: "",
      maxTotalOccupancy: "",
      extraAdultNet: 0,
      extraAdultRack: 0,
      childrenNetTotal: 0,
      childrenRackTotal: 0,
      totalNet: grandTotalNet,
      totalRack: grandTotalRack,
      isTotal: true,
    });

    return mappedRates;
  }, [baselineRates, numAdults, childrenAges]);

  // ✅ Now safely return if no rates exist
  if (tableData.length === 0) {
    return <p>No rates available. Please choose a hotel.</p>;
  }

  return (
    <div>
      <h2>Daily Rate Summary</h2>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "1px solid #ddd",
          marginBottom: "20px",
        }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Date</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Baseline Net</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Baseline Rack</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Baseline Occupancy</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Max Occupancy</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Extra Adults Net</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Extra Adults Rack</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Children Net</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Children Rack</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Total Net</th>
            <th style={{ border: "1px solid #ddd", padding: "8px" }}>Total Rack</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((summary) => (
            <tr key={summary.key} style={summary.isTotal ? { backgroundColor: "#f2f2f2", fontWeight: "bold" } : {}}>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{summary.formattedDate}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{summary.baselineNet.toFixed(2)}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{summary.baselineRack.toFixed(2)}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{summary.baselineOccupancy || ""}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{summary.maxTotalOccupancy || ""}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{summary.extraAdultNet.toFixed(2)}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{summary.extraAdultRack.toFixed(2)}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{summary.childrenNetTotal.toFixed(2)}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px" }}>{summary.childrenRackTotal.toFixed(2)}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px", fontWeight: "bold" }}>{summary.totalNet.toFixed(2)}</td>
              <td style={{ border: "1px solid #ddd", padding: "8px", fontWeight: "bold" }}>{summary.totalRack.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HotelRatesTable;