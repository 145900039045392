// File: src/components/Dashboard/HotelRatesSearch/DatePickerComponent.js
// Purpose: Provides a date range picker for selecting check-in and check-out dates.

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker } from "antd";
import { setDates, fetchAvailableDates, setAvailableDates } from "../../../redux/slices/hotelSearchSlice";
// Duplicate import removed
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const DatePickerComponent = () => {
  const dispatch = useDispatch();
  const selectedDates = useSelector((state) => state.hotelSearch.selectedDates);
  const selectedHotel = useSelector((state) => state.hotelSearch.selectedHotel);
  const availableDates = useSelector((state) => state.hotelSearch.availableDates);
  const lastAvailableDate = useSelector((state) => state.hotelSearch.lastAvailableDate);
  const availableDatesSet = new Set(availableDates.map(date => dayjs(date).format("YYYY-MM-DD")));

  useEffect(() => {
  if (selectedHotel) { // ✅ Ensures API call only happens when a hotel is selected
    dispatch(fetchAvailableDates(selectedHotel));
  } else {
    dispatch(setAvailableDates([])); // ✅ Clears available dates when hotel is cleared
  }
  }, [dispatch, selectedHotel]);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const formattedDates = {
        startDate: dates[0]?.format("YYYY-MM-DD"),
        endDate: dates[1]?.subtract(1, "day").format("YYYY-MM-DD"), // ✅ Subtract 1 day from checkout
      };
      dispatch(setDates(formattedDates));
    } else {
      dispatch(setDates({ startDate: null, endDate: null }));
    }
  };

  const disabledDate = (current) => {
    return !availableDatesSet.has(current.format("YYYY-MM-DD"));
  };

  return (
    <div>
      <label><strong>Select Dates:</strong></label>
      <RangePicker
        value={
          selectedDates?.startDate && selectedDates?.endDate
            ? [dayjs(selectedDates.startDate), dayjs(selectedDates.endDate).add(1, "day")] // ✅ Add back 1 day for UI display
            : null
        }
        onChange={handleDateChange}
        format="DD MMM YYYY"
        placeholder={["Check-in", "Check-out"]}
        style={{ width: "100%" }}
        allowClear
        disabledDate={disabledDate} // Disable unavailable dates
      />
      {lastAvailableDate && (
        <p style={{ textAlign: "center", marginTop: "8px", fontSize: "14px", color: "#666" }}>
          Last available date: {dayjs(lastAvailableDate).format("DD MMM YYYY")}
        </p>
      )}
    </div>
  );
};

export default DatePickerComponent;