// File: src/redux/sagas/transfersSaga.js
// Purpose: Handles all API-related side effects for the Transfers module using Redux-Saga.

import { call, put, takeLatest, all, fork } from 'redux-saga/effects';
import {
    fetchYearsRequest,
    fetchYearsSuccess,
    fetchYearsFailure,
    fetchPickupLocationsRequest,
    fetchPickupLocationsSuccess,
    fetchPickupLocationsFailure,
    fetchDropoffLocationsRequest,
    fetchDropoffLocationsSuccess,
    fetchDropoffLocationsFailure,
    fetchRatesRequest,
    fetchRatesSuccess,
    fetchRatesFailure,
} from '../slices/transfersSlice';

import {
    fetchAvailableYears,
    fetchPickupLocationsByYearId,
    fetchDropOffLocationsByPickup,
    fetchRates,
} from '../../services/transfers_api';

import { transformYears, transformLocations, transformRates } from '../transformers/transfersTransformers';

// Worker Sagas
function* fetchYearsSaga() {
    try {
        const response = yield call(fetchAvailableYears);
        const transformedData = transformYears(response.data.results);
        yield put(fetchYearsSuccess(transformedData));
    } catch (error) {
        yield put(fetchYearsFailure(error.message || 'Failed to fetch years'));
    }
}

function* fetchPickupLocationsSaga(action) {
    try {
        const response = yield call(fetchPickupLocationsByYearId, action.payload);
        const transformedData = transformLocations(response.data.results, 'pickup');
        yield put(fetchPickupLocationsSuccess(transformedData));
    } catch (error) {
        yield put(fetchPickupLocationsFailure(error.message || 'Failed to fetch pickup locations'));
    }
}

function* fetchDropoffLocationsSaga(action) {
    try {
        const response = yield call(fetchDropOffLocationsByPickup, action.payload);
        const transformedData = transformLocations(response.data.results, 'dropoff');
        yield put(fetchDropoffLocationsSuccess(transformedData));
    } catch (error) {
        yield put(fetchDropoffLocationsFailure(error.message || 'Failed to fetch dropoff locations'));
    }
}

function* fetchRatesSaga(action) {
    try {
        const { yearId, pickupLocationId, dropoffLocationId } = action.payload;
        const response = yield call(fetchRates, yearId, pickupLocationId, dropoffLocationId);
        const transformedData = transformRates(response.data.results);
        yield put(fetchRatesSuccess(transformedData));
    } catch (error) {
        yield put(fetchRatesFailure(error.message || 'Failed to fetch rates'));
    }
}

// Watcher Sagas
function* watchFetchYears() {
    yield takeLatest(fetchYearsRequest.type, fetchYearsSaga);
}

function* watchFetchPickupLocations() {
    yield takeLatest(fetchPickupLocationsRequest.type, fetchPickupLocationsSaga);
}

function* watchFetchDropoffLocations() {
    yield takeLatest(fetchDropoffLocationsRequest.type, fetchDropoffLocationsSaga);
}

function* watchFetchRates() {
    yield takeLatest(fetchRatesRequest.type, fetchRatesSaga);
}

// Root Saga
export default function* transfersSaga() {
    yield all([
        fork(watchFetchYears),
        fork(watchFetchPickupLocations),
        fork(watchFetchDropoffLocations),
        fork(watchFetchRates),
    ]);
}
