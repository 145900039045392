// File: src/components/Databoard/Sidebar.js
import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { DashboardOutlined, CarOutlined } from "@ant-design/icons";

const Sidebar = () => {
  return (
    <Menu theme="dark" mode="inline" defaultSelectedKeys={["dashboard"]}>
      <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
        <Link to="/databoard">Dashboard</Link> {/* Points to the Databoard's index route */}
      </Menu.Item>
      <Menu.Item key="transfers" icon={<CarOutlined />}>
        <Link to="/databoard/transfers">Transfers</Link> {/* Points to the Transfers route */}
      </Menu.Item>
    </Menu>
  );
};

export default Sidebar;