// File: src/components/Dashboard/Tours/ToursYearDropdown.js
// Purpose: Dropdown to select a year, with loading and error handling states.
// Related Files:
// - redux/slices/toursDataSlice.js: Manages year data and selected state.
// - redux/slices/toursOperationsSlice.js: Resets quotes when year is cleared.
// - styles/Tours.module.css: Contains styling for dropdown components.

import React, { useEffect } from 'react';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { fetchYears, setSelectedYearId } from '../../../redux/slices/toursDataSlice';
import { resetQuotes } from '../../../redux/slices/toursOperationsSlice';
import {
    selectAvailableYears,
    selectLoadingYears,
    selectErrorYears,
    selectSelectedYearId,
} from '../../../redux/slices/toursSelectors';
import styles from 'styles/Tours.module.css';

const { Option } = Select;

const ToursYearDropdown = () => {
    const dispatch = useDispatch();
    const years = useSelector(selectAvailableYears);
    const loadingYears = useSelector(selectLoadingYears);
    const errorYears = useSelector(selectErrorYears);
    const selectedYearId = useSelector(selectSelectedYearId);

    useEffect(() => {
        console.log('ToursYearDropdown: Fetching years');
        dispatch(fetchYears());
    }, [dispatch]);

    useEffect(() => {
        if (years.length > 0 && !selectedYearId) {
            const year2025 = years.find(year => Number(year.year) === 2025 || Number(year.id) === 2025);
            
            console.log("Checking auto-selection:", { years, selectedYearId, year2025 });

            if (year2025) {
                dispatch(setSelectedYearId(year2025.id));
                dispatch(resetQuotes()); // Ensure quotes reset for the new year selection
            }
        }
    }, [years, dispatch, selectedYearId]);

    const handleYearSelect = (selectedValue) => {
        console.log('Year selected:', selectedValue);
        dispatch(setSelectedYearId(selectedValue));
        if (!selectedValue) {
            dispatch(resetQuotes()); // Reset quotes when year is cleared
        }
    };

    return (
        <div className={styles.selectWrapper}>
            <Select
                value={loadingYears ? 'loading' : selectedYearId}
                onChange={handleYearSelect}
                placeholder={
                    loadingYears
                        ? 'Loading...'
                        : errorYears
                        ? 'Data fetch failed'
                        : 'Select a year'
                }
                allowClear
                className={`${styles.antSelect} ${styles.yearSelect}`}
                style={{ width: '100%' }}
                notFoundContent={loadingYears ? 'Loading...' : 'No options available'}
            >
                {loadingYears ? (
                    <Option value="loading" disabled>
                        Loading...
                    </Option>
                ) : (
                    years.map(({ id, year }) => (
                        <Option key={id} value={id}>
                            {year}
                        </Option>
                    ))
                )}
            </Select>
        </div>
    );
};

export default ToursYearDropdown;
