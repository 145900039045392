// File: src/components/Transfers/RateDisplay.js
// Purpose: Displays rates for the selected year, pickup location, and drop-off location, integrated with Redux and selectors.

import React, { useEffect } from 'react';
import { Table, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectRates,
    selectIsRatesLoading,
    selectSelectedPickupLocation,
    selectSelectedDropoffLocation,
    selectSelectedYearId,
} from '../../../redux/selectors/transfersSelectors';
import { fetchRatesRequest } from '../../../redux/slices/transfersSlice';
import styles from 'styles/Transfers.module.css';

const RateDisplay = () => {
    const dispatch = useDispatch();

    const rates = useSelector(selectRates);
    const loading = useSelector(selectIsRatesLoading);
    const yearId = useSelector(selectSelectedYearId);
    const pickup = useSelector(selectSelectedPickupLocation);
    const dropOff = useSelector(selectSelectedDropoffLocation);

    useEffect(() => {
        if (yearId && pickup?.id && dropOff?.id) {
            dispatch(fetchRatesRequest({ yearId, pickupLocationId: pickup.id, dropoffLocationId: dropOff.id }));
        }
    }, [yearId, pickup, dropOff, dispatch]);

    const handleCopyToClipboard = (rate) => {
        const textToCopy = `Jacamar\tTransfer from ${pickup.name} to ${dropOff.name} for ${rate.groupSize}\t${rate.netRateWithTaxes}\t${rate.rackRateWithTaxes}`;
        navigator.clipboard.writeText(textToCopy);
    };

    const columns = [
        {
            title: 'Group Size',
            dataIndex: 'groupSize',
            key: 'groupSize',
        },
        {
            title: 'Net Rate with Taxes',
            dataIndex: 'netRateWithTaxes',
            key: 'netRateWithTaxes',
            render: (value) => `$${value}`,
        },
        {
            title: 'Rack Rate with Taxes',
            dataIndex: 'rackRateWithTaxes',
            key: 'rackRateWithTaxes',
            render: (value) => `$${value}`,
        },
        {
            title: '',
            key: 'copy',
            render: (_, rate) => (
                <Button
                    onClick={() => handleCopyToClipboard(rate)}
                    type="default"
                    size="small"
                >
                    Copy
                </Button>
            ),
        },
    ];

    if (!rates.length) {
        return <div className={styles.rateDisplay}>Jacamar rates are valid through the end of 2026</div>;
    }

    return (
        <div className={styles.rateDisplay}>
            <Table
                columns={columns}
                dataSource={rates}
                rowKey={(rate) => rate.id}
                pagination={false}
                bordered
                className={styles.rateTable}
                loading={loading}
            />
        </div>
    );
};

export default RateDisplay;
