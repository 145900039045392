// File: src/components/Transfers/TransfersSearchBar.js
// Purpose: Combines dropdown components for selecting year, pickup, and drop-off locations, integrated with Redux.

import React from 'react';
import SelectYearDropdown from './SelectYearDropdown';
import PickupLocationDropdown from './PickupLocationDropdown';
import DropoffLocationDropdown from './DropoffLocationDropdown';
import styles from 'styles/Transfers.module.css';

const TransfersSearchBar = () => {
    return (
        <div className={styles.transfersSearchBar}>
            <div className={styles.yearDropdown}>
                <SelectYearDropdown />
            </div>
            <div className={styles.pickupDropdown}>
                <PickupLocationDropdown />
            </div>
            <div className={styles.dropoffDropdown}>
                <DropoffLocationDropdown />
            </div>
        </div>
    );
};

export default TransfersSearchBar;