// File: src/components/Dashboard/HotelRatesSearch/HotelRatesDisplay.js
// Purpose: Displays hotel rate quotes and enables Google Sheets-friendly copy functionality.

import React, { useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button } from "antd"; // ✅ Import Ant Design Button
import {
  selectBaselineRates,
  selectNumAdults,
  selectChildrenAges,
  selectSelectedDates,
  selectSelectedHotelDetails, // ✅ Fetch Hotel Details from Redux
  selectSelectedRoomTypeDetails, // ✅ Fetch Room Type Details from Redux
} from "../../../redux/selectors/hotelsSelectors";
import { computeFullStayTotals } from "./HotelRatesCalculator";
import HotelRatesTable from "./HotelRatesTable";
import HotelPolicy from "./HotelPolicy";
import styles from "styles/HotelRatesSearch.module.css";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc); // ✅ Enables UTC handling

// ✅ Fixes Date Shift Issue Using Day.js
const formatDate = (dateString) => {
  if (!dateString) return "";
  return dayjs.utc(dateString).format("DD-MMM-YYYY"); // ✅ Ensures UTC consistency
};

const getCheckoutPlusOne = (dateString) => {
  if (!dateString) return "";
  return dayjs.utc(dateString).add(1, "day").format("DD-MMM-YYYY"); // ✅ Adds one day for display
};

const HotelRatesDisplay = () => {
  const baselineRates = useSelector(selectBaselineRates);
  const numAdults = useSelector(selectNumAdults);
  const childrenAges = useSelector(selectChildrenAges);
  const selectedDates = useSelector(selectSelectedDates);
  const selectedHotel = useSelector(selectSelectedHotelDetails); // ✅ Updated usage
  const selectedRoomType = useSelector(selectSelectedRoomTypeDetails); // ✅ Updated usage
  const [showDetails, setShowDetails] = useState(false);
  const [copyStatus, setCopyStatus] = useState("Copy Quote"); // ✅ State for button text
  const hasInvalidRates = baselineRates.some(
    (rate) => rate.net_rate_with_taxes < 1 || rate.rack_rate_with_taxes < 1
  );

  const missingChildrenAges =
    childrenAges.length > 0 && childrenAges.some((age) => age === null || age === undefined);
  const missingData =
    !baselineRates.length || !numAdults || !selectedDates.startDate || !selectedDates.endDate;

  const { grandTotalNet, grandTotalRack } = useMemo(
    () => computeFullStayTotals(baselineRates, numAdults, childrenAges),
    [baselineRates, numAdults, childrenAges]
  );

  const formattedStartDate = formatDate(selectedDates.startDate);
  const formattedEndDate = formatDate(selectedDates.endDate);
  const formattedEndDatePlusOne = getCheckoutPlusOne(selectedDates.endDate);

  const dateRangeString =
    formattedStartDate && formattedEndDate ? `${formattedStartDate} - ${formattedEndDate}` : "No dates selected";

  const handleCopyQuote = () => {
    const copiedDateRange =
      formattedStartDate && formattedEndDatePlusOne
        ? `${formattedStartDate} - ${formattedEndDatePlusOne}`
        : "No dates selected";

    // ✅ Extract Hotel Name & Room Type Name from Redux state
    const hotelName = selectedHotel?.name || "Unknown Hotel";
    const roomTypeName = selectedRoomType?.type || "Unknown Room Type";

    const adultText = numAdults === 1 ? "1 adult" : `${numAdults} adults`;
    const childrenText =
      childrenAges.length === 0
        ? ""
        : childrenAges.length === 1
        ? "1 child"
        : `${childrenAges.length} children`;
    const childrenAgesText = childrenAges.length > 0 ? ` (${childrenAges.join(" & ")})` : "";

    // ✅ Fix: Use Tab (`\t`) as a delimiter instead of `|` for Google Sheets compatibility
    const quoteText = `${copiedDateRange}\t${hotelName}\t${roomTypeName} for ${adultText}${
      childrenText ? ` & ${childrenText}${childrenAgesText}` : ""
    }\t$${grandTotalNet.toFixed(2)}\t$${grandTotalRack.toFixed(2)}`;

    // ✅ Copy formatted text to clipboard
    navigator.clipboard.writeText(quoteText);

    setCopyStatus("Copied!"); // ✅ Change text to "Copied!"
    setTimeout(() => setCopyStatus("Copy Quote"), 1000); // ✅ Reset after 1 second
  };

  if (missingData) return null;

  return (
    <div className={styles.ratesDisplayContainer}>
      <div className={styles.summaryContainer}>
        <h2>Hotel Quote</h2>
        {missingChildrenAges ? (
          <p className={styles.errorMessage}>⚠️ Please provide ages for all children to generate an accurate quote.</p>
        ) : (
          <>
            <p>
              <strong>Net Quote:</strong> ${grandTotalNet.toFixed(2)}
            </p>
            <p>
              <strong>Rack Quote:</strong> ${grandTotalRack.toFixed(2)}
            </p>

            <div className={styles.buttonContainer}>
              {/* ✅ Copy button with dynamic "Copied!" text */}
              <Button type="default" onClick={handleCopyQuote}>
                {copyStatus}
              </Button>

              <Button type="primary" onClick={() => setShowDetails(!showDetails)}>
                {showDetails ? "Hide Daily Breakdown" : "Show Daily Breakdown"}
              </Button>
            </div>

            {showDetails &&
              (hasInvalidRates ? (
                <p className={styles.errorMessage}>
                  ⚠️ There is an issue with the rates in the database. Please contact your database administrator.
                </p>
              ) : (
                <HotelRatesTable baselineRates={baselineRates} numAdults={numAdults} childrenAges={childrenAges} />
              ))}
          </>
        )}
      </div>
      <HotelPolicy />
    </div>
  );
};

export default HotelRatesDisplay;