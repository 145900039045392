// File: src/redux/selectors/transfersSelectors.js
// Purpose: Provides reusable and memoized selectors for accessing state in the Transfers slice.

import { createSelector } from '@reduxjs/toolkit';

// Base Selector
const selectTransfersState = (state) => state.transfers;

// Year Selectors
export const selectYears = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.years
);

export const selectIsYearsLoading = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.loadingYears
);

export const selectYearError = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.errorYears
);

export const selectSelectedYearId = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.selectedYearId
);

// Pickup Location Selectors
export const selectPickupLocations = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.pickupLocations
);

export const selectIsPickupLocationsLoading = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.loadingPickupLocations
);

export const selectPickupLocationsError = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.errorPickupLocations
);

export const selectSelectedPickupLocation = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.selectedPickupLocation
);

// Dropoff Location Selectors
export const selectDropoffLocations = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.dropoffLocations
);

export const selectIsDropoffLocationsLoading = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.loadingDropoffLocations
);

export const selectDropoffLocationsError = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.errorDropoffLocations
);

export const selectSelectedDropoffLocation = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.selectedDropoffLocation
);

// Rates Selectors
export const selectRates = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.rates
);

export const selectIsRatesLoading = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.loadingRates
);

export const selectRatesError = createSelector(
    selectTransfersState,
    (transfersState) => transfersState.errorRates
);

export default {
    selectYears,
    selectIsYearsLoading,
    selectYearError,
    selectSelectedYearId,
    selectPickupLocations,
    selectIsPickupLocationsLoading,
    selectPickupLocationsError,
    selectSelectedPickupLocation,
    selectDropoffLocations,
    selectIsDropoffLocationsLoading,
    selectDropoffLocationsError,
    selectSelectedDropoffLocation,
    selectRates,
    selectIsRatesLoading,
    selectRatesError,
};