// File: src/redux/slices/transfersSlice.js
// Purpose: Redux slice for managing state related to transfers, including years, locations, and rates.

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    years: [],
    loadingYears: false,
    errorYears: null,

    pickupLocations: [],
    loadingPickupLocations: false,
    errorPickupLocations: null,

    dropoffLocations: [],
    loadingDropoffLocations: false,
    errorDropoffLocations: null,

    selectedYearId: null,
    selectedPickupLocation: { id: null, name: '' },
    selectedDropoffLocation: { id: null, name: '' },

    rates: [],
    loadingRates: false,
    errorRates: null,
};

const transfersSlice = createSlice({
    name: 'transfers',
    initialState,
    reducers: {
        // Years
        fetchYearsRequest: (state) => {
            state.loadingYears = true;
            state.errorYears = null;
        },
        fetchYearsSuccess: (state, action) => {
            state.loadingYears = false;
            state.years = action.payload;
        },
        fetchYearsFailure: (state, action) => {
            state.loadingYears = false;
            state.errorYears = action.payload;
        },

        // Pickup Locations
        fetchPickupLocationsRequest: (state) => {
            state.loadingPickupLocations = true;
            state.errorPickupLocations = null;
        },
        fetchPickupLocationsSuccess: (state, action) => {
            state.loadingPickupLocations = false;
            state.pickupLocations = action.payload;
        },
        fetchPickupLocationsFailure: (state, action) => {
            state.loadingPickupLocations = false;
            state.errorPickupLocations = action.payload;
        },

        // Dropoff Locations
        fetchDropoffLocationsRequest: (state) => {
            state.loadingDropoffLocations = true;
            state.errorDropoffLocations = null;
        },
        fetchDropoffLocationsSuccess: (state, action) => {
            state.loadingDropoffLocations = false;
            state.dropoffLocations = action.payload;
        },
        fetchDropoffLocationsFailure: (state, action) => {
            state.loadingDropoffLocations = false;
            state.errorDropoffLocations = action.payload;
        },

        // Year Selection
        selectYear: (state, action) => {
            state.selectedYearId = action.payload;
        },

        // Pickup Location Selection
        selectPickupLocation: (state, action) => {
            state.selectedPickupLocation = action.payload;
            state.dropoffLocations = []; // Clear dropoff locations on pickup selection
            state.selectedDropoffLocation = { id: null, name: '' };
        },

        // Dropoff Location Selection
        selectDropoffLocation: (state, action) => {
            state.selectedDropoffLocation = action.payload;
        },

        // Rates
        fetchRatesRequest: (state) => {
            state.loadingRates = true;
            state.errorRates = null;
        },
        fetchRatesSuccess: (state, action) => {
            state.loadingRates = false;
            state.rates = action.payload;
        },
        fetchRatesFailure: (state, action) => {
            state.loadingRates = false;
            state.errorRates = action.payload;
        },
    },
});

export const {
    fetchYearsRequest,
    fetchYearsSuccess,
    fetchYearsFailure,
    fetchPickupLocationsRequest,
    fetchPickupLocationsSuccess,
    fetchPickupLocationsFailure,
    fetchDropoffLocationsRequest,
    fetchDropoffLocationsSuccess,
    fetchDropoffLocationsFailure,
    selectYear,
    selectPickupLocation,
    selectDropoffLocation,
    fetchRatesRequest,
    fetchRatesSuccess,
    fetchRatesFailure,
} = transfersSlice.actions;

export default transfersSlice.reducer;