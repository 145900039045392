// File: src/components/Dashboard/HotelRatesSearch/HotelSearchBar.js
// Purpose: Manages hotel search using selections stored as IDs. Auto-selects Costa Rica for country,
// resets dependent selections, and clears existing baseline rates when a new hotel is selected.

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedCountry,
  setSelectedRegion,
  setSelectedHotel,
  setSelectedRoomType,
  setDates,
  setBaselineRates,
  setHotels,
  setRoomTypes,
  fetchCountries,
  fetchRegions,
  fetchHotels,
  fetchRoomTypes,
  setLoadingRegions,
  setLoadingHotels,
  setLoadingRoomTypes,
} from "../../../redux/slices/hotelSearchSlice";

import DynamicDropdown from "../../Common/DynamicDropdown";
import DatePickerComponent from "./DatePickerComponent";
import OccupancySelector from "./OccupancySelector";
import styles from "styles/HotelRatesSearch.module.css";

const HotelSearchBar = () => {
  const dispatch = useDispatch();
  const {
    countries,
    regions,
    hotels,
    roomTypes,
    selectedCountry,
    selectedRegion,
    selectedHotel,
    selectedRoomType,
    selectedDates,
    loadingRegions,
    loadingHotels,
    loadingRoomTypes,
    loadingCountries,
  } = useSelector((state) => state.hotelSearch);

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCountry) {
      dispatch(setLoadingRegions(true));
      dispatch(fetchRegions(selectedCountry));
      dispatch(setSelectedRegion(null));
      dispatch(setSelectedHotel(null));
      dispatch(setSelectedRoomType(null));
      dispatch(setBaselineRates([]));
    }
  }, [dispatch, selectedCountry]);

  useEffect(() => {
    if (selectedRegion) {
      dispatch(setLoadingHotels(true));
      dispatch(setHotels([]));
      dispatch(setSelectedHotel(null));
      dispatch(setSelectedRoomType(null));
      dispatch(setBaselineRates([]));
      dispatch(fetchHotels(selectedRegion));
    }
  }, [dispatch, selectedRegion]);

  useEffect(() => {
    if (selectedHotel) {
      dispatch(setLoadingRoomTypes(true));
      dispatch(setRoomTypes([]));
      dispatch(setSelectedRoomType(null));
      dispatch(setBaselineRates([]));
      dispatch(fetchRoomTypes(selectedHotel));
    }
  }, [dispatch, selectedHotel]);

  const selectedRoomData = roomTypes.find((room) => room.id === selectedRoomType);
  const maxOccupancy = selectedRoomData ? selectedRoomData.max_occupancy : null;

  const resetSearch = () => {
    dispatch(setSelectedCountry(null));
    dispatch(setSelectedRegion(null));
    dispatch(setSelectedHotel(null));
    dispatch(setSelectedRoomType(null));
    dispatch(setDates({ startDate: null, endDate: null }));
    dispatch(setBaselineRates([]));
  };

  return (
    <div className={styles.searchBarContainer}>
      <DynamicDropdown
        label="Country"
        data={countries}
        selectedValue={selectedCountry}
        onSelect={(selectedId) => dispatch(setSelectedCountry(selectedId))}
        placeholder="Select Country"
        displayKey="name"
        loading={loadingCountries}
      />

      <DynamicDropdown
        label="Region"
        data={regions}
        selectedValue={selectedRegion}
        onSelect={(selectedId) => dispatch(setSelectedRegion(selectedId))}
        placeholder="Select Region"
        disabled={!selectedCountry}
        displayKey="name"
        loading={loadingRegions}
      />

      <DynamicDropdown
        label="Hotel"
        data={hotels}
        selectedValue={selectedHotel}
        onSelect={(selectedId) => {
          dispatch(setSelectedHotel(selectedId));
          dispatch(setBaselineRates([]));
        }}
        placeholder="Select Hotel"
        displayKey="name"
        showSearch={true}
        disabled={!selectedRegion}
        loading={loadingHotels}
      />

      <DynamicDropdown
        label="Room Type"
        data={roomTypes}
        selectedValue={selectedRoomType}
        onSelect={(selectedId) => dispatch(setSelectedRoomType(selectedId))}
        placeholder="Select Room Type"
        disabled={!selectedHotel}
        displayKey="type"
        loading={loadingRoomTypes}
      />

      <div className={styles.datePickerContainer}>
        <DatePickerComponent />
      </div>

      <div className={styles.occupancySelectorContainer}>
        <OccupancySelector maxOccupancy={maxOccupancy} />
      </div>

      <div className={styles.resetButtonContainer}>
        <button className={styles.resetButton} onClick={resetSearch}>
          Reset Search
        </button>
      </div>
    </div>
  );
};

export default HotelSearchBar;